@use "breakpoints";
@use "colors";
@use "layout";

.hero {
  background-color: colors.$black;
  position: relative;
  z-index: 51;

  .content {
    @include layout.module-content;

    padding-block: 120px 56px;

    @media (width >= breakpoints.$md) {
      padding-block-start: 75px;
    }
  }
}
