@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";

.result-section {
  block-size: 100%;
  min-block-size: 100vh;
}

.result-container {
  @include layout.module-content;

  .empty-message {
    padding: 60px 0;

    h2 {
      @include typography.large-headline;

      color: colors.$black;
      max-inline-size: 500px;
    }

    p {
      @include typography.body-small;
    }
  }
}

.date-container {
  border-block-end: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  margin-block-end: 15px;
  padding: 14px 24px 18px;
}

.no-results {
  padding: 40px 0 61px;

  .message-text {
    font-size: 18px;
    line-height: 26px;
  }

  .card {
    border: none;

    .card-body {
      .card-text {
        font-size: 20px;
      }

      .card-description {
        font-size: 14px;
      }

      .arrow-img {
        block-size: 18px;
        inline-size: 18px;
      }
    }
  }

  @media (width >= breakpoints.$sm) {
    padding: 61px 0 63px;

    .message-text {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.list-header-note {
  @include typography.subhead-regular;

  display: none;
  margin-block: 60px 30px;
}

.result-section[data-club="4147"] {
  min-block-size: auto;
}
