@use "breakpoints";
@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.gallery {
  aspect-ratio: 5 / 8;
  overflow: hidden;
  position: relative;

  video {
    margin: 0;
    object-fit: cover;
  }
}

.gallery-aria-live {
  visibility: hidden;
}

.gallery-slides {
  block-size: 100%;
  overflow: hidden;
  position: relative;
  z-index: 12;

  @include media-breakpoint-down(md) {
    max-block-size: 80vh;
  }

  @include media-breakpoint-down(sm) {
    max-block-size: 80vh;
  }

  @include media-breakpoint-down(md) {
    @media (orientation: landscape) {
      max-block-size: none;
    }
  }
}

.gallery-slides-editorial-title {
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding-block-start: 80px;
  position: absolute;
  z-index: 17;

  h2 {
    @include typography.medium-headline;

    color: colors.$white;
  }
}

.gallery-slides-wrap {
  block-size: 100%;
  inline-size: 100%;
  max-block-size: calc(160vh - 0px);
  position: absolute;
}

.gallery-pager-wrap {
  inline-size: 100%;
  inset-block-end: -100%;
  inset-inline-start: 0;
  position: absolute;
  transition: all 1000ms ease-in-out;
  z-index: 30;

  &-active {
    inset-block-end: 0;
  }

  @include media-breakpoint-down(md) {
    background-color: colors.$black;
    inset-block-end: 0;
    transition: none;
  }
}

.gallery-pager {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  position: relative;
  transition: all 1000ms ease-in-out;
  z-index: 15;

  @include media-breakpoint-down(md) {
    background-color: colors.$black;
    display: block;
    inline-size: 100% !important;
  }
}

.gallery-pager-mobile {
  display: none;
  inset-block-end: 150px;
  inset-inline-start: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 15;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.gallery-pager-mobile-list {
  align-content: flex-end;
  align-items: flex-end;
  display: flex;
  margin: 0;
  padding: 0;

  div {
    background-color: rgb(255 255 255 / 50%);
    block-size: 4px;
    inline-size: 36px;
    margin-inline-end: 7px;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-down(md) {
      span {
        background-color: colors.$white;
      }
    }
  }
}

.gallery-pager-progress-shim {
  background-color: colors.$medium-gray;
  block-size: 6px;
  inline-size: 100%;
  inset-block-end: 0;
  inset-inline-start: 0;
  position: absolute;
  z-index: 10;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

@media (width >= breakpoints.$md) {
  .gallery {
    aspect-ratio: 8 / 5;
  }
}
