// @import "custom";

$duration: 400ms;

.item-enter {
  opacity: 0;
  transition: $duration;
}

.item-enter-active {
  inset-block-start: 0;
  inset-inline-start: 0;
  opacity: 0.9;
  transition: $duration;
}

.item-exit {
  opacity: 0.9;
  transition: $duration;
}

.item-exit-active {
  opacity: 0;
  transition: $duration;
}
