@use "breakpoints";
@use "colors";
@use "typography";

.headline {
  @include typography.subhead-semi-bold;

  margin: 0 0 2.4rem;

  @media (width >= breakpoints.$md) {
    margin: 0 0 3.2rem;
  }
}

.link-container {
  display: flex;
  flex-direction: column;

  @media (width >= breakpoints.$md) {
    flex-direction: row;
  }
}

.numbered-list {
  list-style-type: decimal-leading-zero;
  padding: 0 0 0 2.6rem;
  position: relative;

  @media (width >= breakpoints.$md) {
    padding: 0 0 0 3rem;
  }

  .list-item {
    @include typography.body;

    color: colors.$gray;
    margin: 0 0 2.4rem;
    padding-inline-start: 6px;

    @media (width >= breakpoints.$md) {
      padding-inline-start: 1.5rem;
    }
  }
}

.app-store-links {
  color: white;
  margin: 0.7rem 0 0;
  position: relative;

  @media (width >= breakpoints.$md) {
    inset-block-start: -2px;
    margin: 0 0 0 2rem;
  }

  .link {
    font-weight: 600;
    margin: 0 1.5rem 0 0;

    .underline {
      border-block-end: 1px solid white;
    }

    svg {
      margin: 0 0 0 0.5rem;
    }
  }
}

.footer {
  @include typography.body;

  border: 1px solid rgba(255, 255, 255, 15%);
  color: colors.$gray;
  margin: 3rem 0 0;
  padding: 1.2rem 1.6rem 1.6rem;

  @media (width >= breakpoints.$md) {
    margin: auto 0 0;
    padding: 2.4rem;
  }

  span {
    color: colors.$white;
  }
}

.eqx-membership-id-badge {
  @include typography.body-small;

  border: 1px solid rgba(255, 255, 255, 30%);
  border-radius: 50px;
  display: flex;
  inline-size: 193px;
  margin-block: auto;
  margin-block-end: 10px;
  margin-inline-start: 20px;
  padding: 12px 36px;
}

.resend-link {
  @include typography.body;

  margin-block: auto;
  margin-block-end: 10px;
  margin-inline-start: 10px;
}

.page-container {
  display: flex;
  flex-direction: column;
  padding: 6.4rem 0;

  @media (width >= breakpoints.$lg) {
    min-block-size: 100dvh;
  }
}
