@use "breakpoints";
@use "colors";

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  justify-content: center;
  min-block-size: 100vh;
}

.main a {
  color: colors.$white;
  inline-size: 100%;
  padding-inline-start: 0;
}

.main a,
.menu-disabled {
  border-block-end: 1px solid var(--opacity-white-15, rgba(255, 255, 255, 15%));
  cursor: pointer;
  font-size: 28px;
  line-height: 40px;
  padding-block: 32px;

  @media (width >= breakpoints.$lg) {
    font-size: 42px;
    line-height: 56px;
  }
}

.menu-disabled {
  color: colors.$gray !important;
}

.label-container {
  display: flex;
  flex-direction: column;
  text-align: start;

  @media (width >= breakpoints.$md) {
    text-align: end;
  }
}

.small-label {
  font-size: 11px;
  margin: -16px 0;

  @media (width >= breakpoints.$lg) {
    font-size: 14px;
    margin: -22px 0;
  }
}

.menu-label {
  cursor: pointer;
  line-height: 40px;
  margin: 4px 0 -4px;

  @media (width >= breakpoints.$md) {
    line-height: 56px;
  }
}

.main a:first-child {
  border-block-start: 1px solid
    var(--opacity-white-15, rgba(255, 255, 255, 15%));
}

.menu-item-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.menu-item-container > .icon-container {
  order: 1;

  @media (width >= breakpoints.$md) {
    order: 0;
  }
}

.title-container {
  column-gap: 16px;
  display: flex;
}

.title-container > .label-container {
  order: 1;

  @media (width >= breakpoints.$md) {
    order: 0;
  }
}

.title-container > img {
  block-size: 40px;
  inline-size: 40px;
  order: 0;

  @media (width >= breakpoints.$md) {
    block-size: 56px;
    inline-size: 56px;
    order: 1;
  }
}

.menu-item-container > .title-container {
  order: 0;

  @media (width >= breakpoints.$md) {
    order: 1;
  }
}

.img-disabled {
  opacity: 0.3;
}
