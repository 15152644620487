@use "breakpoints";

.responsive-video {
  block-size: 100%;
  border: 0;
  inline-size: 100%;
  margin: 0;
  min-block-size: 200px;
  object-fit: cover;

  @media (width >= breakpoints.$sm) {
    min-block-size: 100vh;
  }
}
