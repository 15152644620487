@use "breakpoints";
@use "colors";
@use "typography";

.hero {
  &-details {
    a {
      outline: none !important;
    }

    &-inner {
      &-flag {
        margin: 0 0 15px;

        > span {
          background-color: colors.$white;
          color: colors.$black;
          display: inline-block;
          padding: 3px 15px;
        }
      }

      &-nav {
        margin: 58px 0 0;
      }

      &-club-name {
        inline-size: 80%;

        &-ctas {
          margin-block-start: 40px;

          @media (width < breakpoints.$sm) {
            button {
              inline-size: 100%;
            }
          }

          @media (width >= 992px) {
            display: none;
          }
        }

        h2 {
          margin: 0 40px 20px 0;
        }

        p {
          max-inline-size: 508px;
        }
      }

      &-club-details {
        @include typography.body-small;

        inline-size: 20%;

        &-hours {
          margin-block-start: 45px;
        }
      }
    }
  }
}

.hero-description {
  color: colors.$white;
  margin-inline-end: 60px;

  @media (width >= breakpoints.$lg) {
    margin-inline-end: 15px;
  }
}

.hero-details-row {
  display: flex;

  address {
    span {
      @media (width < breakpoints.$md) {
        border-block-end: 1px solid colors.$white;
        color: colors.$white;
        padding-block-end: 1px;
      }
    }
  }
}

body[data-vwo-rrr151="true"] {
  @media (max-width: breakpoints.$md) {
    .hero-club-detail-cta {
      background-color: colors.$white;
      display: flex;
      inline-size: 100%;
      inset-block-end: 0;
      inset-inline-start: 0;
      justify-content: center;
      margin-block-start: 0;
      padding: 20px;
      position: fixed;

      a {
        flex: 1;

        button {
          background-color: colors.$black;
          color: colors.$white;
          max-inline-size: 100%;
        }
      }
    }
  }
}
