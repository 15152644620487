@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";

.coming-soon {
  @include layout.module-content;

  gap: var(--layout-gutter);
  min-block-size: 100vh;
  padding-block-start: 140px;

  @media (width >= breakpoints.$lg) {
    flex-direction: row;
  }
}

.content {
  display: contents;
}

.selling-points {
  order: 3;
}

.headline {
  @include typography.medium-headline;

  order: 1;
}

.photo {
  order: 2;
}

.content,
.photo {
  @media (width >= breakpoints.$lg) {
    display: block;
    flex: 0 0 50%;
    opacity: 0;
    transform: translateY(250px);
  }
}

.img-container {
  block-size: 218px;

  // margin: 50px 0 20px;
  overflow: hidden;
  position: relative;

  @media (width >= breakpoints.$sm) {
    block-size: 488px;
  }

  @media (width >= breakpoints.$md) {
    block-size: 677px;
  }

  img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }
}

.coming-soon-accordion {
  border-block-end: solid colors.$off-black 1px;
  border-block-start: none;
  padding: 30px 0;

  h3 {
    font-size: 20px;
    line-height: 1.4;
    padding-block-end: 0;
  }

  div {
    span {
      color: colors.$medium-gray;
      display: inline-block;
      font-size: 15px;
      line-height: 1.71;
      margin-block-start: 15px;

      @media (width >= breakpoints.$sm) {
        padding-inline-end: 200px;
      }

      @media (width >= breakpoints.$md) {
        padding-inline-end: 0;
      }
    }
  }
}
