@import "custom";

.header {
  font-size: 60px;
  position: relative;
}

.form-wrapper {
  /* stylelint-disable selector-class-pattern */
  :global .MuiFormControl-root {
    margin-block-end: 32px;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap !important;
  }
}

.custom-form {
  padding-inline: 20px !important;
}

.errorMessageStyle {
  color: red;
  inset-block-start: -40px;
  padding: 0 12px;
  position: relative;

  @include media-breakpoint-down(sm) {
    inset-block-start: -60px;
  }
}

.select-container {
  position: relative;

  label {
    font-size: 18px;
    inline-size: 100%;
    user-select: none;
  }

  .error-icon {
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
  }
}
