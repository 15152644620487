@use "breakpoints";
@use "colors";
@use "typography";

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.footer {
  align-items: center;
  aspect-ratio: 17 / 13;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-block-start: 8rem;
  overflow: hidden;
  padding: 2.4rem;
  position: relative;
}

.image {
  inset: 0;
  position: absolute;
}

.text {
  @include typography.xl-headline;

  animation: marquee 48s linear infinite;
  display: flex;
  inset-block-start: calc(50% - 0.5em);
  inset-inline-start: 0;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
}

.subtitle {
  @include typography.body-small;

  text-transform: uppercase;
  z-index: 1;
}

@media (width >= breakpoints.$md) {
  .footer {
    aspect-ratio: 9 / 4;
  }
}
