#module-join-flow {
  background-color: white;
  position: relative;

  .loader {
    align-items: center;
    display: flex;
    justify-content: center;
    min-block-size: 100vh;
  }
}
