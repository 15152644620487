@use "breakpoints";
@use "colors";
@use "typography";

.tabs {
  @include typography.cta-small;

  align-items: center;
  background-color: colors.$black;
  color: colors.$medium-gray;
  display: flex;
  inset-block-start: 0;
  list-style-type: none;
  position: sticky;
  z-index: 10;

  &:active {
    border-block-end: 1px solid colors.$white;
  }

  @media (width >= breakpoints.$md) {
    @include typography.cta;
  }
}

.tab {
  align-items: center;
  block-size: 4.8rem;
  border-block: 1px solid colors.$semi-transparent-white;
  display: flex;
  flex: 1;
  justify-content: center;
  transition:
    border-color 0.3s,
    color 0.3s;

  &:hover,
  &[aria-current="true"] {
    border-block-end-color: colors.$white;
    color: colors.$white;
  }
}

@media (width >= breakpoints.$md) {
  .tab {
    block-size: 6.4rem;
  }
}
