@use "breakpoints";
@use "colors";

.hamburger-button {
  cursor: pointer;
  inline-size: 24px;
  inset-block-start: 36px;
  inset-inline-end: 24px;
  position: absolute;
  z-index: 20;

  hr {
    border: 1px solid colors.$white;
    margin: 8px 0;
  }

  @media (width >= breakpoints.$md) {
    inset-block-start: 32px;
    inset-inline-end: 64px;
  }
}
