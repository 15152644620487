@use "typography";

.facility-hours {
  margin: 0;
  max-block-size: 3em;
  overflow: hidden;
  transition: max-block-size 0.4s ease-in-out;

  &:has(.todays-hours[aria-expanded="true"]) {
    max-block-size: 100dvh;
  }
}

.title {
  @include typography.body-small;
}

.todays-hours {
  align-items: center;
  display: flex;
  min-block-size: 12px;

  svg {
    margin-inline-start: auto;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    cursor: pointer;
  }
}

.hours {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 4px 0 0;
}

.day {
  flex: 1 1 50%;
  font-weight: normal;
  text-transform: capitalize;
}

.times {
  flex: 1 1 50%;
  margin: 0;
  text-align: end;
}

.todays-hours[aria-expanded="true"] {
  svg {
    transform: rotate(180deg);
  }
}
