@use "colors";
@use "typography";

.label {
  color: colors.$white;
  font-size: inherit;
  font-weight: inherit;
}

.white-address {
  color: colors.$white;
}

.gray-address {
  color: colors.$gray;
}

.club-address {
  display: block;
}
