@use "typography";

.holiday-hours {
  border-block-start: 1px solid white;
  margin: 10px 0 0;
  padding-block-start: 10px;
}

.title {
  @include typography.body-small;

  text-transform: capitalize;
}

.hours {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 4px 0 0;
}

.day {
  flex: 1 1 50%;
  font-weight: normal;
  text-transform: capitalize;
}

.times {
  flex: 1 1 50%;
  margin: 0;
  text-align: end;
}
