@use "buttons";
@use "colors";
@use "typography";
@use "breakpoints";

.joining-details {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  margin-block-end: 24px;

  @media (width >= breakpoints.$lg) {
    flex-direction: row;
    gap: 24px;
  }
}

.eqx-tab-title {
  font-size: 3rem;
  font-weight: 600;
}

.eqx-sub-title {
  @include typography.body-small;

  color: colors.$gray;
  margin-block: 8px 0;
}

.eqx-joining-details-card {
  border: 1px solid rgba(255, 255, 255, 30%);
  cursor: pointer;
  margin-block-end: 24px;
  outline: none;
  padding: 24px 24px 0;
  transition: border 0.3s ease-in-out;

  &:focus,
  &[aria-current="true"] {
    border-color: colors.$white !important;
  }

  @media (width >= breakpoints.$lg) {
    margin-block-end: 32px;
  }
}

.eqx-membership-middle {
  display: flex;

  @media (width >= breakpoints.$lg) {
    inline-size: 66%;
    justify-content: space-between;
    margin-block: auto;
  }
}

.eqx-popular-badge {
  @include typography.eyebrow;

  background-color: rgba(149, 132, 255, 15%);
  border-radius: 1px;
  color: #9584ff;
  padding: 4px 6px;
}

.img-fluid {
  aspect-ratio: 2.37;
  max-inline-size: 100%;

  @media (width >= breakpoints.$lg) {
    aspect-ratio: 1.625;
    inline-size: 169px;
  }
}

.spark-img {
  block-size: 17px;
  inline-size: 37px;
  margin-block-start: 21px;
}

.membership-enhancement-card-image {
  block-size: 56px;
  inline-size: 56px;
  margin-block-end: 16px;

  @media (width >= breakpoints.$lg) {
    block-size: 56px;
    inline-size: 56px;
  }
}

.eqx-bullet {
  color: #7a7a7a;
  margin-inline: 8px;
}

.eqx-middle-section {
  @include typography.body-small;

  inline-size: 100%;
  margin-block-end: 8px;
  margin-inline-end: 20px;
}

.eqx-membership {
  @include typography.cta;

  margin-block: 8px;
}

.eqx-title {
  @include typography.body-small;

  color: colors.$gray;
  margin-block-end: 8px;
}

.membership-rate {
  position: relative;
  text-align: center;
}

.eqx-membership-price {
  @include typography.cta;

  margin-block-start: 8px;
}

.eqx-membership-plan {
  @include typography.body-small;

  color: colors.$gray;
  margin: 2px 0 0;
}

.eqx-link {
  display: none;
}

.eqx-membership-enhancement {
  border: 1px solid colors.$semi-transparent-white;
  display: none;
  gap: 10px;
}

.eqx-membership-enhancement-title {
  @include typography.body-small;

  color: colors.$gray;
  padding: 14px 0;
}

.add-enhancement-section {
  border-block-start: 1px solid colors.$semi-transparent-white;
  display: none;
  gap: 16px;
  padding: 24px 0;
}

.add-enhancement-wrapper {
  display: flex;
  flex: 1 0 70%;
  gap: 16px;

  @media (width <= breakpoints.$md) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.enhancement-type {
  inline-size: 152px;
}

.enhancement-title {
  @include typography.cta;
}

.enhancement-sub-title {
  @include typography.body-small;

  color: #aaa;
}

.enhancement-type-3 {
  margin-block-end: 16px;

  @media (width >= breakpoints.$lg) {
    display: block;
    flex: 1 0 15%;
    text-align: end;
  }
}

.eqx-select {
  select {
    background-color: colors.$black;
    border: 1px solid colors.$gray;
    color: colors.$white;
    flex-grow: 3;
    font-size: 14px;
    margin-inline-start: -1px;
    padding: 10px;
  }
}

.add-enhancement-amt {
  @include typography.cta-small;
}

.eqx-join-btn {
  @include buttons.button(buttons.$theme-primary-light, buttons.$width-no-max);

  color: colors.$black;
  font-size: 16px;
  margin-block-start: 24px;
  outline: none;
  text-align: end;

  &:focus {
    outline: 1px solid white;
    outline-offset: 2px;
  }

  @media (width >= breakpoints.$lg) {
    margin-block-start: 32px;
  }
}

.eqx-when-you-join-section {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-block-start: 6.4rem;
}

.join-title {
  @include typography.small-headline;

  inline-size: 34%;
  text-transform: uppercase;
}

.join-bullet-details {
  color: colors.$gray;
  padding-block-end: 12px;
}

.join-bullet-container {
  font-size: 1.8rem;
  padding-inline-start: 0;

  li {
    padding-inline-start: 8px;
  }
}

.join-bullet-content {
  margin-block-end: 12px;
}

.pt-details-section {
  display: flex;
  gap: 24px;
}

.form-select {
  @media (width <= breakpoints.$md) {
    inline-size: 100%;
  }
}
