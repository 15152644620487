@use "breakpoints";
@use "colors";

.menu-img {
  block-size: 40px;
  inline-size: 40px;

  @media (width >= breakpoints.$lg) {
    block-size: 56px;
    inline-size: 56px;
  }
}

.join-today-link {
  background-color: colors.$white;
  border-radius: 50%;
  transform: rotate(180deg);

  @media (width >= breakpoints.$md) {
    transform: none;
  }

  img {
    padding: 30%;
  }
}

.join-today-link.disabled {
  background-color: colors.$gray;
}

.arrow-container {
  transform: rotate(180deg);

  @media (width >= breakpoints.$md) {
    transform: none;
  }
}

.check-container {
  align-items: center;
  block-size: 32px;
  border: 1px solid var(--opacity-white-30, rgba(255, 255, 255, 30%));
  border-radius: 50%;
  display: flex;
  inline-size: 32px;
  justify-content: center;
}
