@use "breakpoints";
@use "colors";
@use "typography";

.opportunity-flow {
  --olp-gutter: 2.4rem;

  display: flex;
  flex-direction: column;

  .content {
    min-inline-size: 0;
    padding-inline: var(--olp-gutter);
  }

  [data-screen="2"] {
    [data-is="TitleCard"] {
      block-size: 400px;
    }
  }

  [data-is="OpportunityFlowTabs"],
  [data-is="OpportunityFlowFooter"] {
    inline-size: calc(100% + 4.8rem);
    margin-inline-start: calc(-1 * var(--olp-gutter));
  }

  [data-is="JoinTodayModule"],
  [data-is="TourAClubModule"],
  [data-is="TryAClassModule"] {
    scroll-margin-block-start: calc(4.8rem + var(--olp-gutter));
  }

  @media (width >= breakpoints.$md) {
    --olp-gutter: 6.4rem;

    flex-direction: row;

    [data-is="TitleCard"],
    .content {
      flex: 0 0 50%;
    }

    [data-is="TitleCard"] {
      block-size: 100vh;
      inset-block-start: 0;
      position: sticky;
    }

    .content {
      padding-inline: var(--olp-gutter);
    }

    [data-is="OpportunityFlowTabs"],
    [data-is="OpportunityFlowFooter"] {
      inline-size: calc(100% + 12.8rem);
      margin-inline-start: calc(-1 * var(--olp-gutter));
    }

    [data-is="JoinTodayModule"],
    [data-is="TourAClubModule"],
    [data-is="TryAClassModule"] {
      scroll-margin-block-start: calc(3.2rem + var(--olp-gutter));
    }
  }
}

.module {
  margin-block: 5.6rem;

  > header {
    display: flex;
    flex-direction: column;
    margin-block-end: 2.4rem;

    h3 {
      @include typography.subhead-semi-bold;

      margin-block-end: 0;
    }
  }

  @media (width >= breakpoints.$md) {
    margin-block: var(--olp-gutter);

    > header {
      margin-block-end: 3.2rem;
    }
  }
}
