@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";

.title-card {
  --gutter-inline: 2.4rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr auto auto;
  position: relative;

  &::before {
    block-size: 4.4rem;
    content: "";
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }

  &::after {
    block-size: 5.6rem;
    content: "";
    grid-column: 1 / span 2;
    grid-row: 9 / span 1;
  }
}

.equinox,
.menu,
.title,
.description,
.facility-name,
.trial-date,
.membership-advisor,
.advisor-phone {
  display: flex;
  margin-inline: var(--gutter-inline);
  z-index: 10;
}

.barcode-class {
  align-items: center;
  grid-column: 1 / span 2;
  grid-row: 5 / span 1;
  padding: 64px 24px 28px;
  z-index: 10;

  @media (width >= breakpoints.$lg) {
    inline-size: 377px;
    padding: 64px 0 16px 64px;
  }
}

.barcode-content {
  backdrop-filter: blur(15px);
  background: var(--opacity-white-7, rgba(255, 255, 255, 7%));
  border: none;
  display: flex;
  gap: 12px;
  padding: 16px;
}

.class-image {
  aspect-ratio: 1;
  block-size: 56px;
  flex: 0;
  flex-shrink: 0;
  inline-size: 56px;
  object-fit: fill;
}

.class-content {
  @include typography.body-medium;

  font-size: 1.6rem;
  padding-block: 1rem;
  text-align: start;

  @media (width >= breakpoints.$lg) {
    padding-inline-end: 45px;
  }
}

.equinox {
  @include layout.equinox-logo;

  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  margin-block-end: 0;
  margin-inline-end: auto;
}

.menu {
  background-color: transparent;
  block-size: 20px;
  border: none;
  display: none;
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  inline-size: 32px;
  margin-inline-start: auto;
}

.title {
  @include typography.large-headline;

  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  margin-block: 6.4rem 1.6rem;
  margin-block-start: 64px;
  white-space: pre-wrap;
  word-break: break-all;

  @media (width >= breakpoints.$sm) {
    margin-block-start: auto;
  }
}

.description {
  @include typography.body;

  color: colors.$gray;
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
  margin-block-end: 4rem;
}

.trial-date,
.facility-name,
.membership-advisor,
.advisor-phone {
  @include typography.body-small;

  align-items: center;
  padding-block: 1.2rem 1.6rem;

  svg:first-of-type {
    margin-inline-end: 1.2rem;
  }

  svg:nth-of-type(2) {
    margin-inline-start: auto;
  }
}

.trial-date,
.facility-name,
.membership-advisor {
  border-block-start: 0.1rem solid rgba(colors.$white, 0.15);
}

.facility-name {
  grid-column: 1 / span 2;
  grid-row: 7 / span 1;
}

.trial-date {
  grid-column: 1 / span 2;
  grid-row: 6 / span 1;
}

.membership-advisor {
  grid-column: 1 / span 2;
  grid-row: 8 / span 1;
}

.advisor-name {
  display: block;
}

.advisor-title {
  color: colors.$gray;
}

.advisor-phone {
  grid-column: 2 / span 1;
  grid-row: 8 / span 1;
  margin-inline-start: auto;

  svg:first-of-type {
    display: none;
  }

  svg:nth-of-type(2) {
    margin-inline-start: 0.8rem;
  }
}

.advisor-phone-number {
  display: none;
}

.background-image {
  grid-column: 1 / span 2;
  grid-row: 1 / span 9;
  inset: 0;
  z-index: 1;

  img {
    object-position: 0% 0%;
  }
}

@media (width >= breakpoints.$md) {
  .title-card {
    --gutter-inline: 6.4rem;

    &::before {
      block-size: 3.2rem;
    }
  }

  .hero-image {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 4.8rem;
  }

  .title {
    margin-block-end: 3.2rem;
  }

  .description {
    margin-block-end: 6.4rem;
  }

  .facility-name {
    border-block-start: none;
    grid-column: 1 / span 1;
    grid-row: 6 / span 1;
    margin-inline-end: 1rem;
  }

  .trial-date {
    grid-column: 1 / span 1;
    grid-row: 7 / span 1;
    margin-inline-end: 1rem;
  }

  .membership-advisor {
    grid-column: 2 / span 1;
    grid-row: 7 / span 1;
    margin-inline-start: 1rem;
  }

  .advisor-phone {
    grid-column: 2 / span 1;
    grid-row: 6 / span 1;
    margin-inline-start: 1rem;

    svg:first-of-type {
      display: block;
    }

    svg:nth-of-type(2) {
      margin-inline-start: auto;
    }
  }

  .advisor-phone-label {
    display: none;
  }

  .advisor-phone-number {
    display: block;
  }

  .background-image {
    grid-row: 1 / span 9;
  }
}

@media (width >= breakpoints.$lg) {
  .hero-image {
    padding: 4.8rem 6.4rem;
  }
}

@media (width >= breakpoints.$xl) {
  .hero-image {
    padding: 9.8rem 14.2rem;
  }

  .advisor-name {
    display: inline;
  }

  .advisor-title {
    &::before {
      content: "•";
      padding-inline: 0.8rem;
    }
  }
}
